import Vue from "vue";

export default {
    getQuestions: async onlyPublished => {
        try {
            const response = await Vue.axios.get("qa", {
                params: {
                    isPublished: onlyPublished,
                },
            });
            return response.data;
        } catch (error) {
            console.log("🚀 ~ file: faq.api.js ~ getQuestions: ~ error", error);
            return [];
        }
    },
    getQuestionById: async id => {
        try {
            const response = await Vue.axios.get(`qa/${id}`);
            return response.data;
        } catch (error) {
            console.log("🚀 ~ file: faq.api.js ~ getQuestionById: ~ error", error);
            return {};
        }
    },
    getCategories: async () => {
        try {
            const response = await Vue.axios.get("qa/categories");
            return response.data;
        } catch (error) {
            console.log("🚀 ~ file: faq.api.js ~ getCategories: ~ error", error);
            return [];
        }
    },
    updateQuestion: async payload => {
        try {
            const response = await Vue.axios.put("qa", payload);
            return response.data;
        } catch (error) {
            console.log("🚀 ~ file: faq.api.js ~ editQuestion: ~ error", error);
            return {};
        }
    },
    createQuestion: async payload => {
        try {
            const response = await Vue.axios.post("qa", payload);
            return response.data;
        } catch (error) {
            console.log("🚀 ~ file: faq.api.js ~ createQuestion: ~ error", error);
            return {};
        }
    },
    deleteQuestion: async id => {
        try {
            const response = await Vue.axios.delete(`qa/${id}`);
            return response.data;
        } catch (error) {
            console.log("🚀 ~ file: faq.api.js ~ deleteQuestion: ~ error", error);
            return {};
        }
    },
};
