<template>
    <div v-loading="loading">
        <div class="top-right-items">
            <div class="flex justify-center">
                <SearchBar @change="searchInputChange" style="margin-right: 15px;" />
                <el-button @click="openAddQuestionModal" type="primary">Ny fråga</el-button>
            </div>
        </div>

        <el-table class="w-full" :data="faqList" size="mini">
            <el-table-column width="375" prop="questionSe" label="Fråga" />
            <el-table-column width="100" prop="answer" label="Svar">
                <template slot="header">
                    <span>Svar</span>
                    <el-dropdown @command="filterByAnswered" trigger="click">
                        <i class="el-icon-arrow-down el-icon--right" />
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="option in answeredOptions" :key="option.value" :command="option.value">
                                {{ option.label }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
                <template slot-scope="{ row }">
                    <div v-if="row.isAnswered">
                        <AnswerViewer :loading="loadingAnswer" :use-dialog="true" :answer="selectedAnswer" :visible="showViewer" @close="handleAnswerViewerClose" />
                        <a @click="openAnswerViewer(row.questionAndAnswerId)">Se svar</a>
                    </div>
                    <div v-else>
                        <a @click="editQuestion(row.questionAndAnswerId)" style="color: red;">Lägg till svar</a>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="category" label="Kategori">
                <template slot="header">
                    <span>Kategori</span>
                    <el-dropdown @command="filterByCategory" trigger="click">
                        <i class="el-icon-arrow-down el-icon--right" />
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="category in categoryOptions" :key="category.nameSe" :command="category.nameSe">
                                {{ category.nameSe }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
                <template slot-scope="{ row }">
                    <el-tag class="mb-1" size="mini" type="success" v-for="(category, key) in row.categories" :key="key">
                        {{ category.name }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="Taggar">
                <template slot-scope="{ row }">
                    <el-tag class="mb-1" type="info" effect="plain" size="mini" v-for="(tag, index) in row.tags" :key="index">#{{ tag }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="companyType" label="Bolagstyp">
                <template slot="header">
                    <span>Bolagstyp</span>
                    <el-dropdown @command="filterByCompanyType" trigger="click">
                        <i class="el-icon-arrow-down el-icon--right" />
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="option in companyTypeOptions" :key="option.label" :command="option.label">
                                {{ option.label }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
                <template slot-scope="{ row }">
                    <el-tag class="mb-1" size="mini" type="warning" v-for="(type, key) in row.companyTypes" :key="key">
                        {{ type }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column width="105" prop="updateEachYear" label="Kontrolleras varje år">
                <template slot="header">
                    <span>Kontrolleras varje år</span>
                    <el-dropdown @command="filterByUpdateEachYear" trigger="click">
                        <i class="el-icon-arrow-down el-icon--right" />
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="option in updateEachYearOptions" :key="option.value" :command="option.value">
                                {{ option.label }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
                <template slot-scope="{ row }">
                    {{ row.everyYear ? "Ja" : "Nej" }}
                </template>
            </el-table-column>
            <el-table-column width="120" prop="published" label="Publicerade">
                <template slot="header">
                    <span>Publicerade</span>
                    <el-dropdown @command="filterByPublished" trigger="click">
                        <i class="el-icon-arrow-down el-icon--right" />
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="option in publishedOptions" :key="option.value" :command="option.value">
                                {{ option.label }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
                <template slot-scope="{ row }">
                    {{ row.isPublished ? "Ja" : "Nej" }}
                </template>
            </el-table-column>
            <el-table-column width="140" label="Redigera/Ta Bort">
                <template slot-scope="{ row }">
                    <i class="fa-light fa-pen mr-3 cursor-pointer" @click="editQuestion(row.questionAndAnswerId)" title="Edit" />
                    <i class="fal mr-3 fa-trash cursor-pointer" @click="openDeleteModal(row)" title="Radera" />
                </template>
            </el-table-column>
        </el-table>
        <AddQuestionModal
            :is-modal-loading="loadingModal"
            @save="handleSave"
            :visible="showAddQuestionModal"
            @close="showAddQuestionModal = false"
            :question="selectedQuestion"
            :all-questions="faqList"
            :editing="isEdit"
        />
        <el-dialog class="flex items-center" v-if="deleteDialogVisible" title="Bekräftelse" :visible.sync="deleteDialogVisible" width="40%">
            <span>Är du säker på att du vill ta bort följande fråga: {{ selectedQuestion.question }}</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="deleteDialogVisible = false">Nej, Behåll den</el-button>
                <el-button type="primary" @click="deleteQuestion">Ja, jag vill ta bort frågan</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Api from "../faq.api.js";
export default {
    name: "TabQuestionList",
    components: {
        AddQuestionModal: () => import("./AddQuestionModal.vue"),
        AnswerViewer: () => import("./AnswerViewer.vue"),
        SearchBar: () => import("./SearchBar.vue"),
    },
    data() {
        return {
            selectedCategory: "Alla",
            selectedCompanyType: "Alla",
            searchQuery: null,
            publishedOnly: null,
            answeredOnly: null,
            updateEachYearOptions: [
                {
                    value: null,
                    label: "Alla",
                },
                {
                    value: true,
                    label: "Ja",
                },
                {
                    value: false,
                    label: "Nej",
                },
            ],
            loading: false,
            loadingAnswer: false,
            loadingModal: false,
            isEdit: false,
            showViewer: false,
            selectedAnswer: null,
            fullFaqList: [],
            faqList: [],
            categoryOptions: [
                {
                    nameSe: "Alla",
                },
                {
                    nameSe: "Fakturering",
                },
                {
                    nameSe: "Kvitton",
                },
                {
                    nameSe: "Självfakturor",
                },
                {
                    nameSe: "Löner",
                },
                {
                    nameSe: "Uttag",
                },
                {
                    nameSe: "Betalningar",
                },
                {
                    nameSe: "Skatter",
                },
                {
                    nameSe: "Årsredovisningar",
                },
                {
                    nameSe: "Prisplan",
                },
                {
                    nameSe: "Allmänt",
                },
            ],
            companyTypeOptions: [
                {
                    label: "Alla",
                },
                {
                    label: "Aktiebolag",
                },
                {
                    label: "Enskild firma",
                },
                {
                    label: "Handelsbolag",
                },
                {
                    label: "Ekonomisk förening",
                },
                {
                    label: "Kommanditbolag",
                },
                {
                    label: "Bostadsrättsförening",
                },
            ],
            selectedQuestion: null,
            showAddQuestionModal: false,
            deleteDialogVisible: false,
            publishedOptions: [
                {
                    value: null,
                    label: "Alla",
                },
                {
                    value: true,
                    label: "Publicerade",
                },
                {
                    value: false,
                    label: "Ej publicerade",
                },
            ],
            answeredOptions: [
                {
                    value: null,
                    label: "Alla",
                },
                {
                    value: true,
                    label: "Besvarade",
                },
                {
                    value: false,
                    label: "Ej besvarade",
                },
            ],
        };
    },
    async created() {
        this.fetchAndUpdateQuestionList();
    },
    methods: {
        async fetchAndUpdateQuestionList() {
            this.loading = true;
            this.fullFaqList = await Api.getQuestions(null);
            this.updateLocalQuestionList();
            this.loading = false;
        },
        updateLocalQuestionList() {
            this.loading = true;
            // Filter the list based on published
            if (this.publishedOnly === true) {
                this.faqList = this.fullFaqList.filter(question => question.isPublished);
            } else if (this.publishedOnly === false) {
                this.faqList = this.fullFaqList.filter(question => !question.isPublished);
            } else {
                this.faqList = [...this.fullFaqList];
            }

            // Filter the list based on answered
            if (this.answeredOnly === true) {
                this.faqList = this.faqList.filter(question => question.isAnswered);
            } else if (this.answeredOnly === false) {
                this.faqList = this.faqList.filter(question => !question.isAnswered);
            }

            // Filter the list based on updateEachYear
            if (this.updateEachYear === true) {
                this.faqList = this.faqList.filter(question => question.everyYear);
            } else if (this.updateEachYear === false) {
                this.faqList = this.faqList.filter(question => !question.everyYear);
            }

            // Filter the list based on category
            if (this.selectedCategory !== "Alla") {
                this.faqList = this.faqList.filter(question => question.categories.some(category => category.name === this.selectedCategory));
            }

            // Filter the list based on company type
            if (this.selectedCompanyType !== "Alla") {
                this.faqList = this.faqList.filter(question => question.companyTypes.some(type => type === "Alla" || type === this.selectedCompanyType));
            }

            // Filter the list based on search query
            if (this.searchQuery) {
                this.faqList = this.faqList.filter(question => this.matchSearchQuery(question, this.searchQuery));
            }
            this.loading = false;
        },
        matchSearchQuery(question, query) {
            query = query.toLowerCase();
            const queryWords = query.split(" ");
            return queryWords.every(word => question.questionSe.toLowerCase().includes(word) || question.tags.some(tag => tag.toLowerCase().includes(word)));
        },
        searchInputChange(query) {
            this.searchQuery = query;
            this.updateLocalQuestionList();
        },
        filterByAnswered(answered) {
            this.answeredOnly = answered;
            this.updateLocalQuestionList();
        },
        filterByPublished(published) {
            this.publishedOnly = published;
            this.updateLocalQuestionList();
        },
        filterByUpdateEachYear(updateEachYear) {
            this.updateEachYear = updateEachYear;
            this.updateLocalQuestionList();
        },
        filterByCategory(categoryName) {
            this.selectedCategory = categoryName;
            this.updateLocalQuestionList();
        },
        filterByCompanyType(companyType) {
            this.selectedCompanyType = companyType;
            this.updateLocalQuestionList();
        },
        openAddQuestionModal() {
            this.selectedQuestion = null;
            this.isEdit = false;
            this.showAddQuestionModal = true;
        },
        async handleSave(question) {
            if (this.isEdit && question) {
                this.showAddQuestionModal = false;
                this.loading = true;
                await Api.updateQuestion(question);
                this.fetchAndUpdateQuestionList();
            } else if (!this.isEdit && question) {
                this.showAddQuestionModal = false;
                this.loading = true;
                await Api.createQuestion(question);
                this.fetchAndUpdateQuestionList();
            }
        },
        async editQuestion(id) {
            this.showAddQuestionModal = true;
            this.isEdit = true;
            this.loadingModal = true;
            const questionData = await Api.getQuestionById(id);
            this.selectedQuestion = { ...questionData };
            this.loadingModal = false;
        },
        openDeleteModal(question) {
            this.selectedQuestion = { ...question };
            this.deleteDialogVisible = true;
        },
        async deleteQuestion() {
            this.deleteDialogVisible = false;
            const id = this.selectedQuestion.questionAndAnswerId;
            await Api.deleteQuestion(id);
            this.fetchAndUpdateQuestionList();
            this.selectedQuestion = null;
        },
        handleAnswerViewerClose() {
            this.showViewer = false;
            this.selectedAnswer = null;
        },
        async openAnswerViewer(id) {
            this.showViewer = true;
            if (this.selectedAnswer === null) {
                this.loadingAnswer = true;
                const questionData = await Api.getQuestionById(id);
                const extractedAnswerFromData = questionData.answerSe;
                this.selectedAnswer = extractedAnswerFromData;
                this.loadingAnswer = false;
            }

            this.loadingAnswer = false;
        },
    },
};
</script>

<style scoped>
.top-right-items {
    position: absolute;
    display: flex;
    align-items: center;
    top: -4rem;
    right: 0;
    margin-top: 0.2rem;
}
</style>
