var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('div',{staticClass:"top-right-items"},[_c('div',{staticClass:"flex justify-center"},[_c('SearchBar',{staticStyle:{"margin-right":"15px"},on:{"change":_vm.searchInputChange}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.openAddQuestionModal}},[_vm._v("Ny fråga")])],1)]),_c('el-table',{staticClass:"w-full",attrs:{"data":_vm.faqList,"size":"mini"}},[_c('el-table-column',{attrs:{"width":"375","prop":"questionSe","label":"Fråga"}}),_c('el-table-column',{attrs:{"width":"100","prop":"answer","label":"Svar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isAnswered)?_c('div',[_c('AnswerViewer',{attrs:{"loading":_vm.loadingAnswer,"use-dialog":true,"answer":_vm.selectedAnswer,"visible":_vm.showViewer},on:{"close":_vm.handleAnswerViewerClose}}),_c('a',{on:{"click":function($event){return _vm.openAnswerViewer(row.questionAndAnswerId)}}},[_vm._v("Se svar")])],1):_c('div',[_c('a',{staticStyle:{"color":"red"},on:{"click":function($event){return _vm.editQuestion(row.questionAndAnswerId)}}},[_vm._v("Lägg till svar")])])]}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v("Svar")]),_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.filterByAnswered}},[_c('i',{staticClass:"el-icon-arrow-down el-icon--right"}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.answeredOptions),function(option){return _c('el-dropdown-item',{key:option.value,attrs:{"command":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1)],1)],2),_c('el-table-column',{attrs:{"prop":"category","label":"Kategori"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.categories),function(category,key){return _c('el-tag',{key:key,staticClass:"mb-1",attrs:{"size":"mini","type":"success"}},[_vm._v(" "+_vm._s(category.name)+" ")])})}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v("Kategori")]),_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.filterByCategory}},[_c('i',{staticClass:"el-icon-arrow-down el-icon--right"}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.categoryOptions),function(category){return _c('el-dropdown-item',{key:category.nameSe,attrs:{"command":category.nameSe}},[_vm._v(" "+_vm._s(category.nameSe)+" ")])}),1)],1)],1)],2),_c('el-table-column',{attrs:{"label":"Taggar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.tags),function(tag,index){return _c('el-tag',{key:index,staticClass:"mb-1",attrs:{"type":"info","effect":"plain","size":"mini"}},[_vm._v("#"+_vm._s(tag))])})}}])}),_c('el-table-column',{attrs:{"prop":"companyType","label":"Bolagstyp"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.companyTypes),function(type,key){return _c('el-tag',{key:key,staticClass:"mb-1",attrs:{"size":"mini","type":"warning"}},[_vm._v(" "+_vm._s(type)+" ")])})}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v("Bolagstyp")]),_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.filterByCompanyType}},[_c('i',{staticClass:"el-icon-arrow-down el-icon--right"}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.companyTypeOptions),function(option){return _c('el-dropdown-item',{key:option.label,attrs:{"command":option.label}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1)],1)],2),_c('el-table-column',{attrs:{"width":"105","prop":"updateEachYear","label":"Kontrolleras varje år"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.everyYear ? "Ja" : "Nej")+" ")]}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v("Kontrolleras varje år")]),_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.filterByUpdateEachYear}},[_c('i',{staticClass:"el-icon-arrow-down el-icon--right"}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.updateEachYearOptions),function(option){return _c('el-dropdown-item',{key:option.value,attrs:{"command":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1)],1)],2),_c('el-table-column',{attrs:{"width":"120","prop":"published","label":"Publicerade"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.isPublished ? "Ja" : "Nej")+" ")]}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v("Publicerade")]),_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.filterByPublished}},[_c('i',{staticClass:"el-icon-arrow-down el-icon--right"}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.publishedOptions),function(option){return _c('el-dropdown-item',{key:option.value,attrs:{"command":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1)],1)],2),_c('el-table-column',{attrs:{"width":"140","label":"Redigera/Ta Bort"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('i',{staticClass:"fa-light fa-pen mr-3 cursor-pointer",attrs:{"title":"Edit"},on:{"click":function($event){return _vm.editQuestion(row.questionAndAnswerId)}}}),_c('i',{staticClass:"fal mr-3 fa-trash cursor-pointer",attrs:{"title":"Radera"},on:{"click":function($event){return _vm.openDeleteModal(row)}}})]}}])})],1),_c('AddQuestionModal',{attrs:{"is-modal-loading":_vm.loadingModal,"visible":_vm.showAddQuestionModal,"question":_vm.selectedQuestion,"all-questions":_vm.faqList,"editing":_vm.isEdit},on:{"save":_vm.handleSave,"close":function($event){_vm.showAddQuestionModal = false}}}),(_vm.deleteDialogVisible)?_c('el-dialog',{staticClass:"flex items-center",attrs:{"title":"Bekräftelse","visible":_vm.deleteDialogVisible,"width":"40%"},on:{"update:visible":function($event){_vm.deleteDialogVisible=$event}}},[_c('span',[_vm._v("Är du säker på att du vill ta bort följande fråga: "+_vm._s(_vm.selectedQuestion.question))]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.deleteDialogVisible = false}}},[_vm._v("Nej, Behåll den")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.deleteQuestion}},[_vm._v("Ja, jag vill ta bort frågan")])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }